import React from "react"
import Layout from "../../components/Layout"
import { StaticImage } from "gatsby-plugin-image"
import "../../assets/css/global.css"
import {Helmet} from "react-helmet"
import Seo from "../../components/Seo"

const WhatNow = () => {
    return (
      <Layout>
        <Helmet>
        <meta charSet="utf-8"/>
        <title>What now?</title>
        <link rel="canonical" href="https://filmchronicle.com/submissions/what-now" />
      </Helmet>
      <Seo title="Film Chronicle" description="By film students, for film students." image="../assets/images/Home-Hero.png" />
        <StaticImage
            src="../../assets/images/Submissions__What-Now-Hero.png"
            alt="flour and eggs"
            className="hero-img"
            placeholder="tracedSVG"
            layout="fullWidth"
          ></StaticImage>
          <div className="hero-container">
          <div className="hero-text">
            <h1 style={{marginBottom: "1.5rem"}}>What Now?</h1>
            <p style={{color: "#fff", fontFamily: "Proxima Nova", fontWeight: "400", textTransform: "none", maxWidth: "40rem"}}>If you’re new to Film Chroncile, we recommend watching the current monthly film festival. If you’re looking for something specific (Action, Horror, etc.) check out our film library.</p>
          </div>
        </div>
      </Layout>
    )
  }
  
  export default WhatNow